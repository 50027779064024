import * as React from "react"
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"
import { trimSlashes, addInitialSlash } from "./utils/localization-helpers"
// Types
import { URL, Section } from "./utils/navigation-constants"
// Components
import NavLink from "./utils/nav-label"
import LocalizedLink from "../links/localizedLink"

interface PlatformManualNavProps {
  resetTab: string
  selectedTab: string
  setNavigationOpen: (arg0: Section) => any
  setResetTab: (arg0: string) => any
  setSelectedTab: (arg0: string) => any
  setMenuOpen: (arg0: boolean) => any
}

const PlatformManualNav: React.FC<PlatformManualNavProps> = ({
  setNavigationOpen,
  resetTab,
  setResetTab,
  selectedTab,
  setSelectedTab,
  setMenuOpen,
}): JSX.Element => {
  const { platformConsoleNavigation, navigation: n } = useLocalizationKeys()

  const navigation = platformConsoleNavigation.map(
    ({ section, subsections }: any): JSX.Element => (
      <div className="manual-nav-section" key={section}>
        <span className="small-title">{useTranslation(section)}</span>
        <ul>
          {subsections.map(({ name, url }: any) => (
            <NavLink
              key={url}
              className={
                selectedTab === trimSlashes(url) ? "api-selected-section" : ""
              }
              label={useTranslation(name)}
              path={url}
              clickHandler={() => {
                setSelectedTab(trimSlashes(url))
                setMenuOpen(false)
              }}
            />
          ))}
        </ul>
      </div>
    )
  )

  return (
    <div className="nav-contents">
      <NavLink
        className="back-link"
        label={`< ${useTranslation(n.backToMainMenu)}`}
        path={resetTab}
        clickHandler={() => {
          setNavigationOpen(Section.DOCS_HOME)
          setResetTab(resetTab)
        }}
      />
      <LocalizedLink
        to={addInitialSlash(URL.PLATFORM_CONSOLE_MANUAL_URL)}
        onClick={() => {
          setSelectedTab(trimSlashes(URL.PLATFORM_CONSOLE_MANUAL_URL))
          setMenuOpen(false)
        }}
      >
        {" "}
        <h2>{useTranslation(n.developerConsoleManual)}</h2>
      </LocalizedLink>
      <section id="base-nav">{navigation}</section>
    </div>
  )
}

export default PlatformManualNav
