import * as React from 'react';
import LocalizedLink from './localizedLink';

const isHash = (str: string) => /^#/.test(str);
const isInternal = (to: string) => /^\/(?!\/)/.test(to);

// Only use <LocalizedLink /> for internal links
const MdxLink = ({ href, ...props }: any) => (isHash(href) || !isInternal(href) ? (
    <a {...props} href={href} target="_blank" rel="noopener noreferrer" />
) : (
    <LocalizedLink {...props} to={href} />
));

export default MdxLink;
