import * as React from 'react';
// Components
import LocalizedLink from '../../links/localizedLink';

interface NavLinkProps {
  className?: string
  label: string
  path: string
  clickHandler: (arg0: any) => any
}

const NavLink: React.FC<NavLinkProps> = ({
    className,
    label,
    path,
    clickHandler,
}: NavLinkProps): JSX.Element => (
    <LocalizedLink to={path} onClick={clickHandler}>
        <h3 className={className}>{label}</h3>
    </LocalizedLink>
);

export default NavLink;
