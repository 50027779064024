// Only one item MUST have the "default: true" key

module.exports = {
    en: {
        default: true,
        path: 'en',
        locale: 'en-US',
        dateFormat: 'DD/MM/YYYY',
        siteLanguage: 'en',
        ogLanguage: 'en_US',
        defaultTitle: 'Using i18n with Gatsby',
        defaultDescription: 'Gatsby example site using MDX and dependency-free i18n',
    },
    jp: {
        path: 'jp',
        locale: 'jp-JP',
        dateFormat: 'DD.MM.YYYY',
        siteLanguage: 'jp',
        ogLanguage: 'jp_JP',
        defaultTitle: 'Gatsbyでi18nを使用する',
        defaultDescription: 'MDXと依存関係のないi18nを使用したGatsbyのサンプルサイト',
    },
};
