import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { trimSlashes, addInitialSlash } from "./utils/localization-helpers"
import { LocaleContext } from "../layout"
// Types
import { Section } from "./utils/navigation-constants"
// Components
import NavLink from "./utils/nav-label"
import LocalizedLink from "../links/localizedLink"
import LocalizedAnchorLink from "../links/localizedAnchorLink"
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"

interface PlatformAPINavProps {
  setNavigationOpen: (arg0: Section) => any
  resetTab: string
  setResetTab: (arg0: string) => any
  selectedTab: string
  setSelectedTab: (arg0: string) => any
  setMenuOpen: (arg0: boolean) => any
}

const PlatformAPINav: React.FC<PlatformAPINavProps> = ({
  setNavigationOpen,
  resetTab,
  setResetTab,
  selectedTab,
  setSelectedTab,
  setMenuOpen,
}): JSX.Element => {
  const { locale: currLocale }: any = React.useContext(LocaleContext)

  const { navigation: n } = useLocalizationKeys()

  // Retrieves the data api navigation text
  const nav = useStaticQuery(query)

  // Build a map of tabs' expanded state
  const [expandedTabs, setExpandedTabs] = React.useState<any>(
    nav.allPlatformApiJson.nodes.reduce((result: any, section: any) => {
      if (trimSlashes(section.path) === selectedTab) {
        result[section.path] = true
      } else {
        result[section.path] = false
      }
      return result
    }, {})
  )

  const createNavLabel = (p: string) => {
    const removedRoot = p.replace(/\platform\/api-info\//g, "")
    const name = removedRoot.replace(/\//g, "")
    const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
    return nameCapitalized
  }

  const localizedBaseNav = nav.allFile.nodes.filter(
    ({
      childMdx: {
        fields: { locale },
      },
    }: any) => locale === currLocale
  )

  // Creates base navigation: Intro, Auth, Errors
  const baseNav = localizedBaseNav.map(
    ({ relativeDirectory, childMdx: { frontmatter: f } }: any): JSX.Element => (
      <NavLink
        key={`${currLocale}/${relativeDirectory}`}
        className={
          selectedTab === trimSlashes(relativeDirectory)
            ? "api-selected-section"
            : ""
        }
        label={createNavLabel(f.title)}
        path={addInitialSlash(relativeDirectory)}
        clickHandler={() => {
          setSelectedTab(trimSlashes(relativeDirectory))
          setMenuOpen(false)
        }}
      />
    )
  )

  const createNavSection = (section: any) => (
    <div key={section.section + section.path}>
      <NavLink
        key={section.path}
        className={
          selectedTab === trimSlashes(section.path)
            ? "api-selected-section"
            : ""
        }
        label={section.section}
        path={section.path}
        clickHandler={() => {
          setSelectedTab(trimSlashes(section.path))
          // Toggle the selected tab expanded state
          setExpandedTabs({
            ...expandedTabs,
            [section.path]: !expandedTabs[section.path],
          })
          setMenuOpen(false)
        }}
      />
      <ul
        className={expandedTabs[section.path] ? "" : "api-subsection-wrapper"}
      >
        {section.dataModel.map((model: any) => (
          <LocalizedLink
            to={`${section.path}#${model.name.replace(/\s/g, "-")}`}
            key={model.name}
          >
            <li>The {model.name} object</li>
          </LocalizedLink>
        ))}
        {section.endpoints.map((subsection: any) => (
          <LocalizedAnchorLink
            to={`${section.path}#${subsection.anchorId}`}
            key={subsection.displayName}
          >
            <li>{subsection.displayName}</li>
          </LocalizedAnchorLink>
        ))}
      </ul>
    </div>
  )

  // Creates api endpoint and data model navigation
  const {
    smartModulesEndpoint,
    mainEndpoint,
  } = nav.allPlatformApiJson.nodes.reduce(
    (result: any, section: any) => {
      if (section.subsection === Section.SMART_MODULE_API_SECTION)
        result.smartModulesEndpoint.push(createNavSection(section))
      else result.mainEndpoint.push(createNavSection(section))

      return result
    },
    { smartModulesEndpoint: [], mainEndpoint: [] }
  )

  return (
    <div className="nav-contents">
      <NavLink
        className="back-link"
        label={`< ${useTranslation(n.backToMainMenu)}`}
        path={resetTab}
        clickHandler={() => {
          setNavigationOpen(Section.DOCS_HOME)
          setResetTab(resetTab)
        }}
      />
      <h2>{useTranslation(n.platformAPITitle)}</h2>
      <section id="base-nav">{baseNav}</section>
      <section className="api-nav underline">{mainEndpoint}</section>
      <section className="api-nav">{smartModulesEndpoint}</section>
    </div>
  )
}

export default PlatformAPINav

const query = graphql`
  query APIPlatformNavQuery {
    allFile(
      filter: { relativeDirectory: { regex: "^/platform/api-info/" } }
      sort: { order: ASC, fields: relativeDirectory }
    ) {
      nodes {
        relativeDirectory
        childMdx {
          fields {
            locale
          }
          frontmatter {
            title
          }
        }
      }
    }
    allPlatformApiJson(
      filter: { type: { eq: "api-doc" } }
      sort: { order: ASC, fields: section }
    ) {
      nodes {
        path
        section
        subsection
        endpoints {
          displayName
          anchorId
        }
        dataModel {
          name
        }
      }
    }
  }
`
