/**
 * This custom hook retrieves all translations from locales and returns the key for each
 */
import { useStaticQuery, graphql } from "gatsby"

const useLocalizationKeys = () => {
  const locallizationConfig = useStaticQuery(query).allNavigationJson.nodes[0]

  return locallizationConfig
}

export default useLocalizationKeys

const query = graphql`
  query MyQuery {
    allNavigationJson {
      nodes {
        contactUsPage {
          company {
            key
            label
            placeholder
          }
          email {
            key
            label
            placeholder
          }
          failureMessage
          japaneseInfoText
          message {
            key
            label
            placeholder
          }
          name {
            key
            label
            placeholder
          }
          phone {
            placeholder
            label
            key
          }
          requiredFieldError
          sendingMessageBtnText
          sendMessageBtnText
          successMessage
          subHeading
          title
        }
        glossary {
          subtitle
          searchPlaceholder
          title
        }
        homeNavigation {
          section
          subsections {
            hasOwnNav
            name
            url
          }
        }
        homepage {
          buttonTextOne
          buttonTextTwo
          consoleIntroduction
          buttonTextThree
          consoleDescription
          contactUsLinkText
          develop
          contactUsText
          footerTitle
          introLineOne
          introLineTwo
          goToConsoleText
          withMode
        }
        navigation {
          headerShortTitle
          googleSearchBar {
            placeholder
            search
          }
          headerSubTitle
          headerTitle
          menu
          backToMainMenu
          developerConsoleManual
          howToGuidesTitle
          platformAPITitle
        }
        notFoundPage {
          firstLine
          modeDevCentral
          secondLine
          title
        }
        platformConsoleNavigation {
          description
          section
          subsections {
            url
            name
          }
        }
        secondaryHomeNavigation {
          isExternalLink
          name
          url
          navLabel
          key
        }
        SEO {
          contactUs {
            description
            title
          }
          glossary {
            description
            title
          }
          notFound {
            description
            title
          }
          homepage {
            description
            title
          }
        }
      }
    }
  }
`
