/**
 * saths
 */

export enum URL {
  PLATFORM_CONSOLE_MANUAL_URL = 'platform/console-manual/table-of-contents',
  PLATFORM_HOW_TO_URL = 'platform/how-to/table-of-contents',
  SENSOR_CLOUD_HOW_TO_URL = 'sensor-cloud/how-to/table-of-contents',
  UI_COMPONENTS_URL = 'platform/entities/ui-settings/table-of-contents',
  REUSABLE_UI_COMPONENTS_URL = 'platform/entities/ui-settings/table-of-contents',
  MOBILITY_UI_COMPONENTS_URL = 'platform/entities/ui-settings/table-of-contents',
  PLATFORM_API_URL = '/platform/api-info/01-intro',
}

/**
 * section names
 */

export enum Section {
  DOCS_HOME = 'docs-home',
  PLATFORM_MANUAL_SECTION = 'platform-manual',
  PLATFORM_HOW_TO_SECTION = 'platform-howto',
  PLATFORM_API_SECTION = 'platform-api',
  SENSOR_CLOUD_HOW_TO_SECTION = 'sc-howto',
  UI_COMPONENTS_SECTION = 'ui-components',
  REUSABLE_UI_COMPONENTS_SECTION = 'ui-reusable',
  MOBILITY_UI_COMPONENTS_SECTION = 'ui-mobility',
  SMART_MODULE_API_SECTION = 'smart-module',
}
