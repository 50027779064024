import * as React from "react"
import "array-flat-polyfill"
import { AiOutlineRight, AiOutlineArrowRight } from "react-icons/ai"
import { addInitialSlash, trimSlashes } from "./utils/localization-helpers"
// Icons
// Types
import {
  NavigationSection,
  NavigationSubsection,
  SecondaryNavigation,
} from "./utils/navigation-interface"
import { URL, Section } from "./utils/navigation-constants"
// Translations
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"
// Components
import LocalizedLink from "../links/localizedLink"

interface HomeNavigationProps {
  location: string
  selectedTab: string
  navigationOpen: Section
  setMenuOpen: (arg0: boolean) => any
  setNavigationOpen: (arg0: Section) => any
  setSelectedTab: (arg0: string) => any
}

const HomeNavigation: React.FC<HomeNavigationProps> = ({
  location,
  selectedTab,
  setSelectedTab,
  setMenuOpen,
  setNavigationOpen,
}: HomeNavigationProps): JSX.Element => {
  const { homeNavigation, secondaryHomeNavigation } = useLocalizationKeys()

  // Given an array of sections, return ar array of URLs of each of the section
  const getUrlRecursive = (subSections: NavigationSubsection[]): string[] =>
    subSections
      .map((subSection: NavigationSubsection): string[] => {
        if (subSection.sub) {
          return getUrlRecursive(subSection.sub)
        }
        return [subSection.url]
      })
      .flat()

  // Map all subsection headings to main section
  const sections: any[] = homeNavigation.reduce(
    (acc: any, node: any) => ({
      ...acc,
      [node.section]: getUrlRecursive(node.subsections),
    }),
    {}
  )

  // State manages whether each section is open in nav bar
  const [sectionExansionState, setExpansionState] = React.useState<any>({})

  const toggleExpansionState = (name: string): void => {
    const newExapansionState = { ...sectionExansionState }
    newExapansionState[name] = !newExapansionState[name]
    setExpansionState(newExapansionState)
  }

  React.useEffect(() => {
    setExpansionState(
      homeNavigation.reduce(
        (sectionStateMap: any, section: NavigationSection) => {
          if (sections[section.section].includes(location))
            sectionStateMap[section.section] = true
          else sectionStateMap[section.section] = false
          return sectionStateMap
        },
        {}
      )
    )
  }, [location])

  const navigation = homeNavigation.map((section: NavigationSection) => (
    <section key={section.section}>
      <button
        onClick={() => toggleExpansionState(section.section)}
        type="button"
      >
        <h3>
          <span
            className={
              sectionExansionState[section.section]
                ? "open-arrow"
                : "close-arrow"
            }
          >
            <AiOutlineRight />
          </span>
          <span>{useTranslation(section.section)}</span>
        </h3>
      </button>
      <ul
        className={
          sectionExansionState[section.section]
            ? "list-display indent-list"
            : "hidden indent-list"
        }
      >
        {section.subsections.map((subsection: NavigationSubsection) => {
          if (subsection.sub) {
            return (
              <div key={`${section.section}${subsection.name}`}>
                <h4>{useTranslation(subsection.name)}</h4>
                {subsection.sub.map((sub: NavigationSubsection) => (
                  <LocalizedLink
                    to={sub.url}
                    key={`${section.section}${sub.name}`}
                    onClick={() => setSelectedTab(trimSlashes(sub.url))}
                  >
                    <li
                      className={
                        selectedTab === trimSlashes(sub.url)
                          ? "selected-tab-indent extra-indent"
                          : "extra-indent "
                      }
                    >
                      {useTranslation(sub.name)}
                    </li>
                  </LocalizedLink>
                ))}
              </div>
            )
          }
          return (
            <LocalizedLink
              to={subsection.url}
              key={`${section.section}${subsection.name}`}
              onClick={() => {
                setMenuOpen(false)
                setSelectedTab(trimSlashes(subsection.url))
                if (subsection.url === addInitialSlash(URL.PLATFORM_API_URL))
                  setNavigationOpen(Section.PLATFORM_API_SECTION)
                if (subsection.url === addInitialSlash(URL.PLATFORM_HOW_TO_URL))
                  setNavigationOpen(Section.PLATFORM_HOW_TO_SECTION)
                if (
                  subsection.url ===
                  addInitialSlash(URL.PLATFORM_CONSOLE_MANUAL_URL)
                )
                  setNavigationOpen(Section.PLATFORM_MANUAL_SECTION)
              }}
            >
              <li
                className={
                  selectedTab === trimSlashes(subsection.url)
                    ? "selected-tab"
                    : ""
                }
              >
                {useTranslation(subsection.name)}
                {subsection.hasOwnNav && <AiOutlineArrowRight />}
              </li>
            </LocalizedLink>
          )
        })}
      </ul>
    </section>
  ))

  return (
    <div className="nav-contents">
      {navigation}
      <section>
        {secondaryHomeNavigation.map(
          (page: SecondaryNavigation): JSX.Element => (
            <button
              key={page.url}
              type="button"
              onClick={() => setSelectedTab("/")}
              className={page.isExternalLink ? "responsive-console-link" : ""}
            >
              <h3 key={page.name}>
                {page.isExternalLink ? (
                  <a href={page.url} target="_blank" rel="noopener noreferrer">
                    {useTranslation(page.navLabel)}
                  </a>
                ) : (
                  <LocalizedLink
                    to={page.url}
                    onClick={() => {
                      setSelectedTab("/")
                      setMenuOpen(false)
                    }}
                  >
                    {useTranslation(page.navLabel)}
                  </LocalizedLink>
                )}
              </h3>
            </button>
          )
        )}
      </section>
    </div>
  )
}

export default HomeNavigation
