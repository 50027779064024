/**
 * This custom hook retrieves all translations from locales and returns the copy for the respective locale
 */
import * as React from "react"
import { LocaleContext } from "../layout"
import translation from "../../utils/i18n/translation"
import locales from "../../utils/i18n/locales"

const useTranslation = (key: string) => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale }: any = React.useContext(LocaleContext)

  // Get the default key. This is currently "en"
  const defaultKey = Object.keys(locales).filter(lang => locales[lang].default)

  const translatedText = translation.translations[locale].devDoc[key]
    ? translation.translations[locale].devDoc[key]
    : translation.translations[defaultKey].devDoc[key]

  return translatedText
}

export default useTranslation
