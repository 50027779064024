import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const APIVersion: React.FC = (): JSX.Element => {
    const { apiVersion, baseURI } = useStaticQuery(
        query,
    ).allPlatformApiJson.nodes[0];

    return (
        <ul>
            <li>
        Version: <span>{apiVersion}</span>
            </li>
            <li>
        Base URL: <span className="inline-code">{baseURI}</span>
            </li>
        </ul>
    );
};

export default APIVersion;

const query = graphql`
  query PlatformAPIVersion {
    allPlatformApiJson(filter: { type: { eq: "api-metadata" } }) {
      nodes {
        apiVersion
        baseURI
      }
    }
  }
`;
