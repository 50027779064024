/**
 * Removes a trailing slash from a path if there is one. ie. /example/route/ => /example/route/
 * @param path
 */
export const removeTrailingSlash = (path: string) =>
  path === "/" ? path : path.replace(/\/$/, "")

/**
 * Localizes route name. ie. /example/route => /jp/example/route
 * @param path
 */
export const localizeSlug = ({ isDefault, locale, slug }: any) =>
  isDefault ? `/${slug}` : `/${locale}/${slug}`

/**
 * Removes the initial slash if there is one. ie. /example/route => example/route
 * @param path
 */
export const removeFirstSlash = (path: string) =>
  path === "/" || path[0] !== "/" ? path : path.replace(/^\//, "")

/**
 * Adds the initial slash if there isn't one. ie. example/route => /example/route
 * @param path
 */
export const addInitialSlash = (path: string) =>
  path !== "/" && path[0] !== "/" ? `/${path}` : path

/**
 * Removes the trailing and initial slasshes if there exists ones. ie. /example/route/ => example/route
 * @param path
 */
export const trimSlashes = (path: string) =>
  removeFirstSlash(removeTrailingSlash(path))

/**
 * Removes the locale from a path if there exists one ie. /jp/example/route/ => /example/route
 * @param path
 */
export const removeLocale = (path: string, locale: string) => {
  const regex = new RegExp(`/^${locale}|^\/${locale}/`)
  return path === "/" ? path : path.replace(regex, "")
}
