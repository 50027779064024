/**
 * This layout component surrounds all pages in the site
 * This is handled in gatsby-config file
 */

import * as React from 'react';
import { Location } from '@reach/router';
import { MDXProvider } from '@mdx-js/react';
// Components
import MdxLink from './links/mdxLink';
import Navigation from './navigation/nav-container';
import ScHowToTableOfContents from './tables/SCHowToTableOfContents';
import APIVersion from './api/api-version';
import PlatformManualTableOfContents from './tables/PlatformManualTableOfContents';
import PlatformHowToTableOfContents from './tables/PlatformHowToTableOfContents';

interface LayoutProps {
  children: React.ReactNode
  pageContext: PageContext
}

interface PageContext {
  locale: string
}
7;

export const LocaleContext = React.createContext({});

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page so the locale available everywhere
const Layout: React.FC<LayoutProps> = ({
    children,
    pageContext: { locale },
}): JSX.Element => (
    <LocaleContext.Provider value={{ locale }}>
        <Location>
            {({ location }) => (
                <div className="main-page">
                    <Navigation location={location.pathname} locale={locale} />
                    <MDXProvider
                        components={{
                            a: MdxLink,
                            ScHowToTableOfContents,
                            APIVersion,
                            PlatformManualTableOfContents,
                            PlatformHowToTableOfContents,
                        }}
                    >
                        <main>{children}</main>
                    </MDXProvider>
                </div>
            )}
        </Location>
    </LocaleContext.Provider>
);

export default Layout;
