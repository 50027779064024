import * as React from "react"
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"
// Components
import LocalizedLink from "../links/localizedLink"

/**
 * This is the table of contents for the Console Manual.
 * This is made dynamically from console-manual-nav.json file.
 * The route is: /platform/console-manual/table-of-contents.
 */

const PlatformManualTableOfContents: React.FC = (): JSX.Element => {
  const { platformConsoleNavigation } = useLocalizationKeys()

  return platformConsoleNavigation.map(
    (section: any): JSX.Element => (
      <div key={section.section}>
        <h2>{useTranslation(section.section)}</h2>
        {section.description && <p>{useTranslation(section.description)}</p>}
        <ul>
          {section.subsections.map(
            (subsection: any): JSX.Element => (
              <li key={subsection.url}>
                <LocalizedLink to={subsection.url}>
                  {useTranslation(subsection.name)}
                </LocalizedLink>
              </li>
            )
          )}
        </ul>
      </div>
    )
  )
}
export default PlatformManualTableOfContents
