import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { addInitialSlash } from "../navigation/utils/localization-helpers"
import { LocaleContext } from "../layout"
import LocalizedLink from "../links/localizedLink"

const SCHowToTableOfContents: React.FC = (): JSX.Element => {
  const { locale: currLocale }: any = React.useContext(LocaleContext)

  // Retrieves the data api navigation text
  const list = useStaticQuery(query).allFile.nodes.filter(
    (n: any) => n.childMdx && n.childMdx.frontmatter.section === "sc-howto"
  )

  const localizedList = list.filter(
    ({
      childMdx: {
        fields: { locale },
      },
    }: any) => locale === currLocale
  )

  return localizedList.map(
    (
      {
        relativeDirectory,
        childMdx: {
          frontmatter: { title },
          fields: { locale },
        },
      }: any,
      i: number
    ): JSX.Element => (
      <h3 key={`${locale}${relativeDirectory}`}>
        <em>{i + 1}</em>
        <LocalizedLink to={addInitialSlash(relativeDirectory)}>
          {title}
        </LocalizedLink>
      </h3>
    )
  )
}

export default SCHowToTableOfContents

const query = graphql`
  query SCHowToTOCQuery {
    allFile {
      nodes {
        relativeDirectory
        childMdx {
          frontmatter {
            title
            section
          }
          fields {
            locale
            isDefault
          }
        }
      }
    }
  }
`
