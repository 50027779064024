import * as React from "react"
import { trimSlashes, addInitialSlash } from "./utils/localization-helpers"
// Types
import { Section } from "./utils/navigation-constants"
// Components
import NavLink from "./utils/nav-label"
import LocalizedLink from "../links/localizedLink"
// Translations
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"

/**
 * How To Navigation Component is a list of unordered titles
 * There is a header at top to lead to the component title page
 * This is used for Sensor Cloud How To and Platform How To
 */

interface HowToNavProps {
  resetTab: string
  locale: string
  tableOfContentsURL: string
  selectedTab: string
  listOfArticles: any
  setNavigationOpen: (arg0: Section) => any
  setResetTab: (arg0: string) => any
  setSelectedTab: (arg0: string) => any
  setMenuOpen: (arg0: boolean) => any
}

const HowToNav: React.FC<HowToNavProps> = ({
  setNavigationOpen,
  tableOfContentsURL,
  resetTab,
  setResetTab,
  selectedTab,
  setSelectedTab,
  setMenuOpen,
  listOfArticles,
  locale,
}): JSX.Element => {
  const { navigation: n }: any = useLocalizationKeys()
  const localizedList = listOfArticles.filter(
    ({
      node: {
        childMdx: {
          fields: { locale: currLocale },
        },
      },
    }: any) => locale === currLocale
  )

  const navigation = localizedList.map(
    ({
      node: {
        childMdx: { frontmatter: f },
        relativeDirectory,
      },
    }: any): JSX.Element => (
      <NavLink
        key={`${locale}${relativeDirectory}`}
        className={
          selectedTab === relativeDirectory ? "api-selected-section" : ""
        }
        label={f.title}
        path={addInitialSlash(relativeDirectory)}
        clickHandler={() => {
          setSelectedTab(relativeDirectory)
          setMenuOpen(false)
        }}
      />
    )
  )

  return (
    <div className="nav-contents">
      <NavLink
        className="back-link"
        label={`< ${useTranslation(n.backToMainMenu)}`}
        path={resetTab}
        clickHandler={() => {
          setNavigationOpen(Section.DOCS_HOME)
          setResetTab(resetTab)
        }}
      />
      <LocalizedLink
        to={addInitialSlash(tableOfContentsURL)}
        onClick={() => setSelectedTab(trimSlashes(tableOfContentsURL))}
      >
        <h2>{useTranslation(n.howToGuidesTitle)}</h2>
      </LocalizedLink>
      <section id="base-nav">{navigation}</section>
    </div>
  )
}

export default HowToNav
