import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  AiOutlineArrowRight,
  AiOutlineSearch,
  AiOutlineClose,
} from "react-icons/ai"
import {
  addInitialSlash,
  trimSlashes,
  removeLocale,
} from "./utils/localization-helpers"
// Components
import PlatformAPINav from "./platformapi-nav"
import PlatformManualNav from "./platformconsole-nav"
import HomeNav from "./home-nav"
import HowToNav from "./howto-nav"
import LocalizedLink from "../links/localizedLink"
import useLocalizationKeys from "../localization-utils/useLocalizationKeys"
import useTranslation from "../localization-utils/useTranslation"
// Types
import { URL, Section } from "./utils/navigation-constants"
import {
  SecondaryNavigation,
  PlatformAPIMetaData,
} from "./utils/navigation-interface"

interface NavigationProps {
  location: string
  locale: string
}

const Navigation: React.FC<NavigationProps> = ({
  location,
  locale,
}): JSX.Element => {
  // Normalize location string wheter has a "/" at the ends
  const directoryLocation = trimSlashes(removeLocale(location, locale))

  const { navigation, secondaryHomeNavigation } = useLocalizationKeys()

  // Retrieves the navigation for all pages
  const data = useStaticQuery(query)
  const platformAPIPages = data.allSitePage.nodes
  const allMDXFiles = data.allFile.edges.filter((n: any) => n.node.childMdx)

  const developerConsole = secondaryHomeNavigation.filter(
    (nav: SecondaryNavigation) => nav.key === "Developer Console"
  )[0]

  // Sets the current panel based on window location; default is main nav
  let displayPage = Section.DOCS_HOME

  // Check if the current page is Platform API article
  platformAPIPages.forEach((page: PlatformAPIMetaData) => {
    if (trimSlashes(page.path) === directoryLocation) {
      displayPage = Section.PLATFORM_API_SECTION
    }
  })

  // Check if the current page is Platform How To article

  const listOfPlatformHowToArticles = allMDXFiles.filter(
    ({
      node: {
        childMdx: {
          frontmatter: { section },
        },
      },
    }: any) => section === Section.PLATFORM_HOW_TO_SECTION
  )

  if (directoryLocation === URL.PLATFORM_HOW_TO_URL) {
    displayPage = Section.PLATFORM_HOW_TO_SECTION
  }

  // Check if the current page is Console Manual article

  if (directoryLocation === URL.PLATFORM_CONSOLE_MANUAL_URL) {
    displayPage = Section.PLATFORM_MANUAL_SECTION
  }

  // Check if the current page is SC How To article
  const listOfSCHowToArticles = allMDXFiles.filter(
    ({
      node: {
        childMdx: {
          frontmatter: { section },
        },
      },
    }: any) => section === Section.SENSOR_CLOUD_HOW_TO_SECTION
  )

  listOfSCHowToArticles.forEach(({ node: { relativeDirectory } }: any) => {
    if (relativeDirectory === directoryLocation) {
      displayPage = Section.SENSOR_CLOUD_HOW_TO_SECTION
    }
  })

  if (directoryLocation === URL.SENSOR_CLOUD_HOW_TO_URL) {
    displayPage = Section.SENSOR_CLOUD_HOW_TO_SECTION
  }

  for (let i = 0; i < allMDXFiles.length; i += 1) {
    if (allMDXFiles[i].node.relativeDirectory === directoryLocation) {
      if (
        allMDXFiles[i].node.childMdx.frontmatter.section ===
        Section.PLATFORM_HOW_TO_SECTION
      ) {
        displayPage = Section.PLATFORM_HOW_TO_SECTION
        break
      }
      if (
        allMDXFiles[i].node.childMdx.frontmatter.section ===
        Section.SENSOR_CLOUD_HOW_TO_SECTION
      ) {
        displayPage = Section.SENSOR_CLOUD_HOW_TO_SECTION
        break
      }
      if (
        allMDXFiles[i].node.childMdx.frontmatter.section ===
        Section.PLATFORM_MANUAL_SECTION
      ) {
        displayPage = Section.PLATFORM_MANUAL_SECTION
        break
      }
    }
  }

  // State manages which navigation panel is currently displayed
  const [navigationOpen, setNavigationOpen] = React.useState<Section>(
    displayPage
  )
  // State manages which page/url is currently open
  const [selectedTab, setSelectedTab] = React.useState<string>(
    directoryLocation
  )

  // State manages responsive header for when menu is displayed, closed by default
  // Needed for responsive header
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)

  // Re-renders when location changes
  React.useEffect(() => {
    setSelectedTab(directoryLocation)
    setNavigationOpen(displayPage)
  }, [directoryLocation])

  // State captures search text and boolean for if search box is open
  const [searchText, setSearchText] = React.useState<string>("")
  const [searchDisplay, setSearchDisplay] = React.useState<boolean>(false)

  // Formats search text for google site search
  const captureSearchText = (event: React.SyntheticEvent) => {
    const val = (event.target as HTMLTextAreaElement).value
    const scrubbedVal = val.trim().replace(/\s+/g, "+")
    setSearchText(scrubbedVal)
  }

  return (
    <div id="nav-wrapper">
      <section id="nav-main" onClick={() => setSearchDisplay(false)}>
        <div id="side-nav-header">
          <LocalizedLink
            to="/"
            onClick={() => {
              setSelectedTab("/")
              setNavigationOpen(Section.DOCS_HOME)
            }}
          >
            <h1>
              <span>{useTranslation(navigation.headerTitle)}</span>
              <span id="small-title">
                {useTranslation(navigation.headerSubTitle)}
              </span>
            </h1>
          </LocalizedLink>
        </div>
        <div id="responsive-nav-header-wrapper">
          {menuOpen ? (
            <div id="responsive-nav-header-open">
              <button type="button" onClick={() => setMenuOpen(false)}>
                <AiOutlineClose className="icon-close" />
                <h1>{useTranslation(navigation.menu)}</h1>
              </button>
            </div>
          ) : (
            <div id="responsive-site-header-closed">
              <LocalizedLink to="/" onClick={() => setSelectedTab("/")}>
                <h1>{useTranslation(navigation.headerShortTitle)}</h1>
              </LocalizedLink>
              <button type="button" onClick={() => setMenuOpen(true)}>
                <p>{useTranslation(navigation.menu)}</p>
              </button>
            </div>
          )}
        </div>
        <div id={menuOpen ? "nav-main-wrapper" : "nav-main-wrapper-hidden"}>
          <nav>
            {navigationOpen === Section.DOCS_HOME && (
              <HomeNav
                selectedTab={selectedTab}
                location={addInitialSlash(directoryLocation)}
                navigationOpen={navigationOpen}
                setMenuOpen={setMenuOpen}
                setSelectedTab={setSelectedTab}
                setNavigationOpen={setNavigationOpen}
              />
            )}
            {navigationOpen === Section.PLATFORM_API_SECTION && (
              <PlatformAPINav
                setNavigationOpen={setNavigationOpen}
                resetTab="/"
                setMenuOpen={setMenuOpen}
                setResetTab={setSelectedTab}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            )}
            {navigationOpen === Section.PLATFORM_HOW_TO_SECTION && (
              <HowToNav
                tableOfContentsURL={URL.PLATFORM_HOW_TO_URL}
                listOfArticles={listOfPlatformHowToArticles}
                setMenuOpen={setMenuOpen}
                setNavigationOpen={setNavigationOpen}
                resetTab="/"
                setResetTab={setSelectedTab}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                locale={locale}
              />
            )}
            {navigationOpen === Section.PLATFORM_MANUAL_SECTION && (
              <PlatformManualNav
                setMenuOpen={setMenuOpen}
                setNavigationOpen={setNavigationOpen}
                resetTab="/"
                setResetTab={setSelectedTab}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            )}
            {navigationOpen === Section.SENSOR_CLOUD_HOW_TO_SECTION && (
              <HowToNav
                tableOfContentsURL={URL.SENSOR_CLOUD_HOW_TO_URL}
                listOfArticles={listOfSCHowToArticles}
                setMenuOpen={setMenuOpen}
                setNavigationOpen={setNavigationOpen}
                resetTab="/"
                setResetTab={setSelectedTab}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                locale={locale}
              />
            )}
          </nav>
        </div>
      </section>
      <div className="bottom-links-wrapper">
        <section
          className={searchDisplay ? "whole-button" : "half-button"}
          id="google-search-wrapper"
        >
          {!searchDisplay && (
            <a
              className="search-icon-wrapper"
              onClick={() => setSearchDisplay(!searchDisplay)}
            >
              <AiOutlineSearch />
              <p>{useTranslation(navigation.googleSearchBar.search)}</p>
            </a>
          )}
          {searchDisplay && (
            <form
              onSubmit={e => {
                e.preventDefault()
                window.open(
                  `https://www.google.com/search?q=site:dev.tinkermode.com+${searchText}`,
                  "_blank"
                )
              }}
            >
              <input
                type="text"
                placeholder={useTranslation(
                  navigation.googleSearchBar.placeholder
                )}
                onChange={captureSearchText}
                autoFocus
              />
              <button
                type="submit"
                className={searchText.length === 0 ? "disabled-btn" : ""}
                disabled={searchText.length === 0}
              >
                <p>{useTranslation(navigation.googleSearchBar.search)}</p>
              </button>
            </form>
          )}
        </section>
        {!searchDisplay && (
          <a
            href={developerConsole.url}
            target="_blank"
            rel="noopener noreferrer"
            className="half-button"
            id="console-btn-large"
          >
            <p>{useTranslation(developerConsole.navLabel)}</p>
            <AiOutlineArrowRight />
          </a>
        )}
      </div>
    </div>
  )
}

export default Navigation

const query = graphql`
  query NavigationData {
    allSitePage(filter: { path: { regex: "//platform/api/" } }) {
      nodes {
        path
      }
    }
    allFile {
      edges {
        node {
          childMdx {
            frontmatter {
              title
              section
            }
            fields {
              locale
              isDefault
            }
          }
          relativeDirectory
        }
      }
    }
  }
`
